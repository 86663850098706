import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { RateCardSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';

@Injectable()
export class RateCardApi {
  private endpoint = 'rate_cards/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    const params = {
      include: 'TimeBlock',
      allow_deleted: '1',
    };

    return this.gateway.get(this.endpoint, { params }).pipe(
      map((res) => reformatListResponse('RateCard', res)),
      map((data) => normalize(data, arrayOf(RateCardSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  add(rateCardData): Observable<any> {
    return this.gateway.post(this.endpoint, rateCardData).pipe(
      map((res) => reformatEntityResponse('RateCard', res)),
      map((data) => normalize(data, RateCardSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(id, data): Observable<any> {
    return this.gateway.put(this.endpoint + id, data).pipe(
      map((res) => reformatEntityResponse('RateCard', res)),
      map((data) => normalize(data, RateCardSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  remove(id): Observable<any> {
    return this.gateway.delete(this.endpoint + id);
  }

  fetch(rateCardId): Observable<any> {
    return this.gateway.get(this.endpoint + '/' + rateCardId).pipe(
      map((res) => reformatEntityResponse('RateCard', res)),
      map((data) => normalize(data, RateCardSchema, { assignEntity: assignSchemaEntity }))
    );
  }
}
