import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { AppState } from '../../index';
import { filterDeleted, mapAndSortEntities, mapEntity } from '../../shared/entity.helper';
import { RateCardAction } from './rate-card.action';
import { RateCardApi } from './rate-card.api';
import { RateCardModel, RateCardState } from './rate-card.model';

@Injectable()
export class RateCardService {
  public constructor(
    private store: Store<AppState>,
    private api: RateCardApi,
  ) {}

  public load() {
    this.store.dispatch(RateCardAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(RateCardAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(RateCardAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(rateCardData): Observable<any> {
    this.store.dispatch(RateCardAction.add(rateCardData));

    return this.api.add(rateCardData).pipe(
      map((response) => {
        this.store.dispatch(RateCardAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(RateCardAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, rateCardData) {
    this.store.dispatch(RateCardAction.update(rateCardData));

    return this.api.update(id, rateCardData).pipe(
      map((response) => {
        this.store.dispatch(RateCardAction.updateSuccess(id, response));

        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(RateCardAction.updateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public delete(id) {
    this.store.dispatch(RateCardAction.remove(id));

    return this.api.remove(id).pipe(
      tap(() => {
        this.store.dispatch(RateCardAction.removeSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(RateCardAction.removeFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    this.store.dispatch(RateCardAction.fetch(id));

    return this.api.fetch(id).pipe(
      map((response) => {
        this.store.dispatch(RateCardAction.fetchSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(RateCardAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(rateCardData) {
    if (rateCardData.RateCard.id) {
      return this.update(rateCardData.RateCard.id, rateCardData);
    }

    rateCardData = u({ RateCard: u.omit('id') }, rateCardData);

    return this.add(rateCardData);
  }
}

export const sortRateCards = (rateCards: RateCardModel[]): RateCardModel[] =>
  sortBy(rateCards, [(rateCard) => Number(rateCard.order), (rateCard) => Number(rateCard.id)]);

export const getRateCardState = (appState: AppState): RateCardState => appState.orm.rateCards;

export const getRateCardIds = compose((state) => state.items, getRateCardState);
export const getRateCardEntities = createSelector(getRateCardState, (state) => state.itemsById);
export const getRateCards = createSelector(getRateCardIds, getRateCardEntities, mapAndSortEntities(sortRateCards));

export const getRateCard = (id: string) => createSelector(getRateCardEntities, (entities) => mapEntity(id, entities));

export const getActiveRateCards = createSelector(getRateCards, (rateCards: RateCardModel[]) =>
  filterDeleted(rateCards),
);
